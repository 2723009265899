/**
 * Created by fworld on 2019/12/21.
 */
export default {
  path: 'equipment',
  name: 'equipment',
  component: () => import('@/views/Equipment/redirect'),
  children: [
    {
      path: 'department',
      name: 'equipment.department',
      meta: {
        bg: true,
        break: true,
        title: '部门管理'
      },
      component: () => import('@/views/Equipment/Department'),
    },
    {
      path: 'device',
      name: 'equipment.device',
      meta: {
        bg: true,
        break: true,
        title: '设备管理'
      },
      component: () => import('@/views/Equipment/Device'),
    },
    {
      path: 'orders',
      name: 'equipment.orders',
      meta: {
        bg: true,
        break: true,
        title: '订单管理'
      },
      component: () => import('@/views/Equipment/Orders'),
    },
    {
      path: 'account',
      name: 'equipment.account',
      meta: {
        bg: true,
        break: true,
        title: '帐号管理'
      },
      component: () => import('@/views/Equipment/Employee'),
    },
    {
      path: 'setting',
      name: 'equipment.setting',
      meta: {
        bg: true,
        break: true,
        title: '基础设置'
      },
      component: () => import('@/views/Equipment/Setting'),
    },
  ]
}
