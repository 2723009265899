/**
 * Created by fworld on 2019/12/21.
 */
export default {
  path: 'work',
  name: 'work',
  component: () => import('@/views/Equipment/redirect'),
  children: [
    {
      path: 'company',
      name: 'work.company',
      meta: {
        bg: true,
        break: true,
        title: '企业管理'
      },
      component: () => import('@/views/Work/Company'),
    },
    {
      path: 'device',
      name: 'work.device',
      meta: {
        bg: true,
        break: true,
        title: '设备分配'
      },
      component: () => import('@/views/Work/Company/device'),
    },
  ]
}
