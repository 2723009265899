/**
 * Created by fworld on 2019/12/21.
 */
export default {
  path: 'account',
  name: 'account',
  redirect: {name: 'account.roles'},
  component: () => import('@/views/redirect'),
  meta: {
    title: '',
  },
  children: [
    {
      path: 'roles',
      name: 'account.roles',
      meta: {
        bg: true,
        break: true,
        title: '角色权限'
      },
      component: () => import('@/views/Account/Role'),
    },
  ]
}
