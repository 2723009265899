<template>
  <router-view/>
</template>

<script>

  export default {
    name: '',
    components: {},
    computed: {},

    mounted: function () {
    },
    data: function () {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss">
  @import 'assets/style/element_reset.scss';

  body {
    @include set_bg_color($background-color-light, $background-color-dark);
    height: 100%;
    width: 100%;
  }
</style>
