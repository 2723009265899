import request from '@/util/request';

/**
 * 登录
 * @param {phone,uuid,password} data
 */
export function employeeLogin(data) {
  return request({
    url: `/api/v1/account/employee/login`,
    method: 'POST',
    data,
  })
}

export function employeeGetCode(data) {
  return request({
    url: `/api/v1/account/employee/code`,
    method: 'POST',
    data,
  })
}

/**
 * 获得用户登录详情
 * @param {*jobNumber} data
 */
export function employeeRefresh(pramas) {
  return request({
    url: `/api/v1/account/employee/refresh`,
    method: 'POST',
    pramas,
  })
}


/**
 * 手机检测(获取企业编号)
 */
export function employeeHasBindEnterprise(data) {
  return request({
    url: `/api/v1/account/employee/enterprise/hasbind`,
    method: 'POST',
    data,
  })
}


/**
 * 获取用于重置密码的操作码
 */
export function forgetPasswordCode(phone) {
  return request({
    url: `/api/v1/account/employee/forgetPasswordCode?phone=${phone}`,
    method: 'POST',
  })
}

/**
 * 重置密码
 */
export function resetPassword(data) {
  return request({
    url: `/api/v1/account/employee/resetPassword`,
    method: 'POST',
    data,
  })
}


export function logout() {
  return new Promise(function (resolve) {
    resolve()
  })
}

export function employeeModules() {
  return request({
    url: `/api/v1/account/employee/modules`,
    method: 'get',
  })
}

export function employeeList(params) {
  return request({
    url: `/api/v1/account/employee/list`,
    method: 'GET',
    params,
  })
}

export function employeeUnion(params) {
  return request({
    url: `/api/v1/account/employee/union`,
    method: 'GET',
    params,
  })
}

export function employeeCreateCode(params) {
  return request({
    url: `/api/v1/account/employee/create/code`,
    method: 'GET',
    params,
  })
}

export function employeeCreate(data) {
  return request({
    url: `/api/v1/account/employee/create`,
    method: 'POST',
    data,
  })
}

export function enterpriseResetEmployeePassword(data) {
  return request({
    url: `/api/v1/account/enterprise/reset/employee/password`,
    method: 'POST',
    data,
  })
}

export function employeeUpdate(data) {
  return request({
    url: `/api/v1/account/employee/update`,
    method: 'POST',
    data,
  })
}

export function employeeDelete(params) {
  return request({
    url: `/api/v1/account/employee/delete`,
    method: 'GET',
    params,
  })
}

export function employeeBelongEnterprise() {
  return request({
    url: `/api/v1/account/employee/enterprise/belong`,
    method: 'GET',
  })
}

export function employeeCanChoice() {
  return request({
    url: `/api/v1/account/employee/canChoice`,
    method: 'GET',
  })
}

export function employeeSwitchEnterprise(params) {
  return request({
    url: `/api/v1/account/employee/switchEnterprise`,
    method: 'GET',
    params,
  })
}


export function employeeChangePassword(data) {
  return request({
    url: `/api/v1/account/employee/change/password`,
    method: 'POST',
    data,
  })
}






