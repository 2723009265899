import Vue from 'vue'
import Router from 'vue-router'
import equipment from './equipment.js'
import work from './work.js'
import account from './account.js'

Vue.use(Router)

export default new Router({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login'),
    },
    {
      path: '/main',
      name: 'home',
      component: () => import('@/layout/main'),
      children: [
        {...equipment},
        {...work},
        {...account},
      ]
    },
    {
      path: '*',    // 此处需特别注意至于最底部
      redirect: '/main'
    }
  ]
})

