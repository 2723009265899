<template>
  <div>
    <span class="back" @click="$router.back()"><i class="iconfont icon-fanhui"></i> 返回</span>
  </div>
</template>

<script>
    export default {
        name: '',
        components: {},
        mounted: function () {
        },
        data: function () {
            return {}
        },
        methods: {},
    }
</script>

<style lang="scss">

  .back {
    cursor: pointer;
  }

  .back:hover {
    color: $_theme_color;
  }


</style>
