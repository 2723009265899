/**
 * Created by fworld on 2019/9/11.
 */
import types from '../mutationTypes';


export default {
  state: {
    navMenus: [],
    employee: {
      corpid: '',
    },
    selectNavMenu: {
      uuid: '',
      name: ''
    },
    menus: [],
    routerMap: {},
    page: {
      icon: 'iconfont iconshujufenxi-jingyinggaikuang',
      name: '整体概况',
      explain: '',
      bg: true,
      break: true,
    },
    groups: [],
  },
  mutations: {
    [types.SET_ENTERPRISE_NAV_MENU](state, menu) {
      state.navMenus = menu.navMenus || []
      state.navMenus.every(navMenu => {
        for (let i = 0; i < navMenu.modules.length; i++) {
          let m = navMenu.modules[i]
          if (menu.routerName.indexOf(m.webRouterName) !== -1) {
            state.selectNavMenu.uuid = navMenu.uuid
            state.selectNavMenu.name = navMenu.name
            state.menus = navMenu.modules
            return false
          }
        }
        return true
      })

    },
    [types.SET_ENTERPRISE_EMPLOYEE](state, employee) {
      if (employee.uuid && employee.uuid.indexOf("w") === 0) {
        employee.corpid = employee.uuid
      } else {
        employee.corpid = ''
      }
      if (!employee.code) {
        employee.code = state.employee.code
      }
      state.employee = employee
    },

    [types.SET_SELECT_MENUS](state, navMenu) {
      state.selectNavMenu.uuid = navMenu.uuid
      state.selectNavMenu.name = navMenu.name
      state.menus = navMenu.modules
    },


    [types.SET_ACCOUNT_GROUP](state, groups) {
      state.groups = groups
    },

    [types.SET_EMPLOYEE_CORPID](state, corpid) {
      state.employee.corpid = corpid
    },
  },
  actions: {
    setEnterpriseMenu({commit}, info) {
      commit(types.SET_ENTERPRISE_NAV_MENU, info)
    },

    setEnterpriseEmployee({commit}, employee) {
      commit(types.SET_ENTERPRISE_EMPLOYEE, employee)
    },


    setSelectMenus({commit}, navMenu) {
      commit(types.SET_SELECT_MENUS, navMenu)
    },

    setEmployeeCorpid({commit}, corpid) {
      commit(types.SET_EMPLOYEE_CORPID, corpid)
    },


    logout({commit}) {
      commit(types.SET_ENTERPRISE_EMPLOYEE, {})
    },
  }
}
